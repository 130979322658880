import React, { useState } from "react";
import "./surveyer.css";

const CreateSurveyer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      name,
      email,
      password,
    };

    try {
      const response = await fetch(
        "https://www.rcinfovision.in/api/users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        const errorData = await response.json();
        setMessage(
          errorData.message || "Registration failed. Please try again."
        );
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="sign-f">
          <form className="form-sgsurvey" onSubmit={handleSubmit}>
            <h3 className="sign-h">Surveyer Registration</h3>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              className="sign-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="sign-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              className="sign-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <br />
            <br />
            <button type="submit" className="sign-b mb-2">
              Create
            </button>
            <br />
          </form>
          {message && <p className="message">{message}</p>}
        </div>
      </div>
    </>
  );
};

export default CreateSurveyer;

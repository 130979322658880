import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Qrcode from "../qrcode/Qrcode";
import ShowQr from "../qrcode/ShowQr";
import "./dashboard.css";
import CreateSurveyer from "../surveyer/CreateSurveyer";
import SurveyerList from "./SurveyerList";
import Datatable from "../qrcode/Datatable";
// import GenerateQr from "../qrcode/GenerateQr";

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("empProfile");

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="app">
          <div className="row">
            <Header collapsed={collapsed} onToggle={toggleSidebar} />
          </div>
          <div className="main-secd">
            <div className="row">
              <div className="col-lg-2 side-secd">
                <Sidebar
                  collapsed={collapsed}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="col-lg-10 main-secr">
                <div className="main-content">
                  {activeTab === "qrcode" && <Qrcode />}
                  {activeTab === "createsurveyer" && <CreateSurveyer />}
                  {activeTab === "surveyerlist" && <SurveyerList />}
                  {activeTab === "showqr" && <ShowQr />}
                  {/* {activeTab === "generateqr" && <GenerateQr />} */}
                  {activeTab === "datatable" && <Datatable />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./web.css";
import logo from "../img/rcilogo.jpeg";

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    navigate("/");
  };

  return (
    <>
      <section className="header-top">
        <div className="container">
          <div className="row">
            <div className="header-home">
              <img src={logo} alt="logo" className="img-fluid logo" />
              <div>
                <i
                  className="fa fa-sign-out"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color: "#FF0000",
                  }}
                  onClick={handleLogout}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;

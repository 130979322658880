import { useNavigate } from "react-router-dom";
import Icon from "../icon/notif.png";

import { FaSistrix } from "react-icons/fa";
import "./dashboard.css";

const Header = ({ collapsed, onToggle }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <header className="header">
      <div className="row">
        <div className="col-lg-2 box-h1">
          <button className="header-toggle-button" onClick={onToggle}>
            <i className="fa fa-bars"></i>
          </button>
          <span className="dash-title">RCI</span>
        </div>
        <div className="col-lg-4 mt-2 box-h2">
          <form>
            <input
              type="text"
              id="searchInput"
              placeholder="Search.."
              required
              className="dash-input"
            />
            <button type="submit" className="sdash-icon">
              <FaSistrix />
            </button>
          </form>
        </div>
        <div className="col-lg-6 pro-box">
          <img src={Icon} alt="notification" className="noti-icon" />
          <i
            className="fa fa-sign-out mt-2"
            style={{ fontSize: "20px", cursor: "pointer", color: "#FF0000" }}
            onClick={handleLogout}
          ></i>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import QRdetails from "./web/Qrdetails";
import Login from "./component/Login";
import Dashboard from "./component/Dashboard";
import SurveyerHome from "./web/SurveyerHome";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/surveyerhome"
          element={isAuthenticated ? <SurveyerHome /> : <Navigate to="/" />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
        />
        {/* <Route path="/api/get-details/qr/:uid" element={<QRdetails />} /> */}
        <Route path="/get-details/qr/:uid" element={<QRdetails />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

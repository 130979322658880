import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dashboard.css";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://www.rcinfovision.in/api/users/vendors"
        );
        const usersWithStatus = response.data.map((user) => ({
          ...user,
          isActive: false,
        }));
        setUsers(usersWithStatus);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const toggleUserStatus = (id) => {
    setUsers(
      users.map((user) =>
        user._id === id ? { ...user, isActive: !user.isActive } : user
      )
    );
  };

  const handleChangePage = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(users.length / itemsPerPage);
  const currentItems = users.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    let endPage = Math.min(
      totalPages,
      currentPage + Math.floor(maxPageNumbersToShow / 2)
    );

    if (currentPage <= Math.floor(maxPageNumbersToShow / 2)) {
      endPage = Math.min(totalPages, maxPageNumbersToShow);
    }

    if (currentPage + Math.floor(maxPageNumbersToShow / 2) >= totalPages) {
      startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handleChangePage(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="container mt-5">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>S. No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((user, index) => (
            <tr key={user._id}>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                <span
                  className="action-icons"
                  onClick={() => toggleUserStatus(user._id)}
                >
                  {user.isActive ? (
                    <i
                      className="fa fa-toggle-on"
                      style={{ cursor: "pointer", color: "green" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-toggle-off"
                      style={{ cursor: "pointer", color: "red" }}
                    ></i>
                  )}
                  Active/Deactive
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => handleChangePage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPageNumbers()}
        <button
          onClick={() => handleChangePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserTable;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../surveyer/surveyer.css";

const Sidebar = ({ collapsed, activeTab, setActiveTab }) => {
  const [openDropdown, setOpenDropdown] = useState("");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDropdownClick = (dropdownName) => {
    setOpenDropdown((prevDropdown) =>
      prevDropdown === dropdownName ? "" : dropdownName
    );
  };

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <ul className="nav">
        {!collapsed && (
          <>
            <div className="without-nav my-2">
              <li
                className={activeTab === "qrcode" ? "active" : ""}
                onClick={() => handleTabClick("qrcode")}
              >
                <i className="fas fa-qrcode me-2"></i>
                <span className="org-tit">Generate Qrcode</span>
              </li>
            </div>
            <div className="without-nav my-2">
              <li
                className={activeTab === "datatable" ? "active" : ""}
                onClick={() => handleTabClick("datatable")}
              >
                <i className="fas fa-qrcode me-2"></i>
                <span className="org-tit">Datatable</span>
              </li>
            </div>
            <div className="without-nav my-2">
              <li
                className={activeTab === "showqr" ? "active" : ""}
                onClick={() => handleTabClick("showqr")}
              >
                <i className="fas fa-qrcode me-2"></i>
                <span className="org-tit">Show Qrcode</span>
              </li>
            </div>
            <li className="drop-btn">
              <a
                className="btn drop-menu"
                data-bs-toggle="collapse"
                href="#collapseAnotherSection"
                role="button"
                aria-expanded={
                  openDropdown === "anotherSection" ? "true" : "false"
                }
                aria-controls="collapseAnotherSection"
                onClick={() => handleDropdownClick("anotherSection")}
              >
                <i className="fas fa-folder me-2"></i>
                Surveyer
                <i
                  className={`fas fa-caret-${
                    openDropdown === "anotherSection" ? "down" : "up"
                  } org-icon ms-5`}
                ></i>
              </a>
            </li>
            <div
              className={`collapse ${
                openDropdown === "anotherSection" ? "show" : ""
              }`}
              id="collapseAnotherSection"
            >
              <div className="card card-body">
                <NavLink
                  className={activeTab === "createsurveyer" ? "active" : ""}
                  onClick={() => handleTabClick("createsurveyer")}
                >
                  <i className="fas fa-file"></i>{" "}
                  <span className="org-tit">Create Surveyer</span>
                </NavLink>
                <NavLink
                  className={activeTab === "surveyerlist" ? "active" : ""}
                  onClick={() => handleTabClick("surveyerlist")}
                >
                  <i className="fas fa-file"></i>{" "}
                  <span className="org-tit">Surveyer List</span>
                </NavLink>
              </div>
            </div>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;

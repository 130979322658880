import React, { useEffect, useState } from "react";
import axios from "axios";

const ShowQr = () => {
  const [qrCodes, setQRCodes] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalForms, setTotalForms] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the data from the API
    const loadForms = async () => {
      try {
        setLoading(true);
        const response = await fetchData(limit, (page - 1) * limit);
        setQRCodes(response.data.data);
        setTotalForms(response.data.total);
      } catch (err) {
        setError("There was an error fetching the QR code data!");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadForms();
  }, [page, limit]);

  const fetchData = async (limit = 0, offset = 0) => {
    try {
      const response = await axios.get(
        `https://www.rcinfovision.in/api/get-all-qrs?limit=${limit}&offset=${offset}`
        // `http://localhost:5000/api/get-all-qrs?limit=${limit}&offset=${offset}`
      );
      return response;
    } catch (error) {
      setError("There was an error fetching the QR code data!");
      console.error(error);
    }
  };

  const handleChangePage = (pages) => {
    if (pages < 1 || pages > totalPages) return;
    setPage(pages);
    // setOffset(limit * pages);
  };

  const totalPages = Math.ceil(totalForms / limit);
  // const currentItems = qrCodes.slice((page - 1) * limit, page * limit);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    let startPage = Math.max(1, page - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(
      totalPages,
      page + Math.floor(maxPageNumbersToShow / 2)
    );

    if (page <= Math.floor(maxPageNumbersToShow / 2)) {
      endPage = Math.min(totalPages, maxPageNumbersToShow);
    }

    if (page + Math.floor(maxPageNumbersToShow / 2) >= totalPages) {
      startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
    }
    // console.log(startPage,endPage)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handleChangePage(i)}
          className={i === page ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <h2>QR Codes</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>QR ID</th>
                <th>QR Code URL</th>
              </tr>
            </thead>
            <tbody>
              {qrCodes?.map((qrCode, index) => (
                <tr key={qrCode._id}>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>{qrCode.qrId}</td>
                  <td>{qrCode.qrCodeUrl}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 1}
            >
              Previous
            </button>
            {renderPageNumbers()}
            <button
              onClick={() => handleChangePage(page + 1)}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowQr;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = ({ setIsAuthenticated }) => {
  const [emailMobile, setEmailMobile] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isAdmin = emailMobile === "admin@gmail.com";

    const loginData = {
      email: emailMobile,
      password,
      ...(isAdmin && { isAdmin: true }),
    };

    try {
      const response = await fetch(
        "https://www.rcinfovision.in/api/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { role, token } = data.user;

        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", token);

        setIsAuthenticated(true);

        if (role === "Vendor") {
          navigate("/surveyerhome");
        } else if (role === "Admin") {
          navigate("/dashboard");
        } else {
          setMessage("Login successful, but no valid role found.");
        }
      } else {
        const errorData = await response.json();
        setMessage(
          `Login failed. ${
            errorData.message || "Please check your credentials and try again."
          }`
        );
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <section className="surveyer-login-bg">
      <div className="container">
        <div className="sign-f">
          <form className="form-sg" onSubmit={handleSubmit}>
            <h3 className="sign-h">Login</h3>
            <input
              type="text"
              id="emailMobile"
              name="emailMobile"
              placeholder="Email/Mobile Number"
              className="sign-input"
              value={emailMobile}
              onChange={(e) => setEmailMobile(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              className="sign-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <br />
            <br />
            <button type="submit" className="sign-b">
              Login
            </button>
            <br />
            {message && <p className="message">{message}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;

import React from "react";
import SurveyerForm from "./SurveyerForm";
import Header from "./Header";
import Footer from "./Footer";

const SurveyerHome = () => {
  return (
    <>
      <Header />
      <SurveyerForm />
      <Footer />
    </>
  );
};

export default SurveyerHome;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const QRdetails = () => {
  const { uid } = useParams();
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(`Fetching details for UID: ${uid}`);
    fetch(`https://www.rcinfovision.in/api/get-details/qr/${uid}`)
      .then((response) => {
        console.log("Response status:", response.status);
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data);
        if (data && Object.keys(data).length > 0) {
          setDetails(data);
        } else {
          setError("This plate has no data, this is an unused plate.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        if (error.message === '{"error":"Form not found"}') {
          setError("This plate has no data, this is an unused plate.");
        } else {
          setError("An error occurred while fetching data.");
        }
        setLoading(false);
      });
  }, [uid]);

  if (loading) {
    return (
      <>
        <Header />
        <section className="qrdetails-sec">
          <div className="container my-5">
            <h2>Loading...</h2>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <section className="qrdetails-sec">
          <div className="container my-5">
            <h2 className="text-center my-4 rajinfovision">
              <span style={{ color: "#02AFFE" }}>Raj Care Infovision </span>
              <br />
              <span style={{ color: "#7B91A2" }}>Digital Door Plate Form</span>
            </h2>
            <h5>User Details</h5>
            <div className="alert alert-warning" role="alert">
              {error}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <section className="qrdetails-sec">
        <div className="container my-5">
          <h2 className="text-center my-4 rajinfovision">
            <span style={{ color: "#02AFFE" }}>Raj Care Infovision </span>
            <br />
            <span style={{ color: "#7B91A2" }}>Digital Door Plate Form</span>
          </h2>
          <h5>User Details</h5>
          <div className="table-container">
            <table className="table table-bordered">
              <thead>
                <tr className="trh">
                  <th>UID</th>
                  <th>Name</th>
                  <th>House No</th>
                  <th>Mobile</th>
                  <th>State</th>
                  <th>Municipality</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="UID">{details.UID}</td>
                  <td data-label="Name">{details.name}</td>
                  <td data-label="House No">{details.house_no}</td>
                  <td data-label="Mobile">{details.mobile}</td>
                  <td data-label="State">{details.state}</td>
                  <td data-label="Municipality">{details.municipality}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default QRdetails;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const PropertyForm = () => {
  const [qrId, setQrId] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [gpsLocation, setGpsLocation] = useState("");
  const [nameOfHouseOwner, setNameOfHouseOwner] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [municipalWardSectorNo, setMunicipalWardSectorNo] = useState("");
  const [residentSince, setResidentSince] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [totalFamilyMembers, setTotalFamilyMembers] = useState("");
  const [nonAadharFamilyMembers, setNonAadharFamilyMembers] = useState("");
  const [nonVoterFamilyMembers, setNonVoterFamilyMembers] = useState("");
  const [passport, setPassport] = useState("");
  const [typeOfOwnership, setTypeOfOwnership] = useState("");
  const [statusOfProperty, setStatusOfProperty] = useState("");
  const [propertyTaxPay, setPropertyTaxPay] = useState("");
  const [lastPaymentYear, setLastPaymentYear] = useState("");
  const [govtWaterFacility, setGovtWaterFacility] = useState("");
  const [boreWell, setBoreWell] = useState("");
  const [latrineToilet, setLatrineToilet] = useState("");
  const [electricity, setElectricity] = useState("");
  const [caste, setCaste] = useState("");
  const [totalPlotArea, setTotalPlotArea] = useState("");
  const [totalBuildCoveredArea, setTotalBuildCoveredArea] = useState("");
  const [buildAreaFloorDetails, setBuildAreaFloorDetails] = useState("");
  const [inPlotAreaCommercialUse, setInPlotAreaCommercialUse] = useState("");
  const [doorToDoorGarbageCollection, setDoorToDoorGarbageCollection] =
    useState("");
  const [garbageCollectionBy, setGarbageCollectionBy] = useState("");
  const [agriculturalLand, setAgriculturalLand] = useState("");
  const [courtCase, setCourtCase] = useState("");
  const [familyMemberCriticalDisease, setFamilyMemberCriticalDisease] =
    useState("");
  const [nameOfDisease, setNameOfDisease] = useState("");
  const [nameOfMember, setNameOfMember] = useState("");
  const [domesticAnimal, setDomesticAnimal] = useState("");
  const [rentedPerson, setRentedPerson] = useState("");
  // const [numberOfRentedFamilyMember, setNumberOfRentedFamilyMember] =
  //   useState("");
  const [buildingImage, setBuildingImage] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setGpsLocation(`${latitude}, ${longitude}`);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Unable to retrieve GPS location.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleImageChange = (e) => {
    setBuildingImage(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const vendorId = user ? user.vendorId : null;

    const data = new FormData();
    data.append("qrId", qrId);
    data.append("vendorId", vendorId);
    data.append("state", state);
    data.append("district", district);
    data.append("municipality", municipality);
    data.append("policeStation", policeStation);
    data.append("gpsLocation", gpsLocation);
    data.append("nameOfHouseOwner", nameOfHouseOwner);
    data.append("houseNo", houseNo);
    data.append("municipalWardSectorNo", municipalWardSectorNo);
    data.append("residentSince", residentSince);
    data.append("mobileNo", mobileNo);
    data.append("pincode", pincode);
    data.append("totalFamilyMembers", totalFamilyMembers);
    data.append("nonAadharFamilyMembers", nonAadharFamilyMembers);
    data.append("nonVoterFamilyMembers", nonVoterFamilyMembers);
    data.append("passport", passport);
    data.append("typeOfOwnership", typeOfOwnership);
    data.append("statusOfProperty", statusOfProperty);
    data.append("propertyTaxPay", propertyTaxPay);
    data.append("lastPaymentYear", lastPaymentYear);
    data.append("govtWaterFacility", govtWaterFacility);
    data.append("boreWell", boreWell);
    data.append("latrineToilet", latrineToilet);
    data.append("electricity", electricity);
    data.append("caste", caste);
    data.append("totalPlotArea", totalPlotArea);
    data.append("totalBuildCoveredArea", totalBuildCoveredArea);
    data.append("buildAreaFloorDetails", buildAreaFloorDetails);
    data.append("inPlotAreaCommercialUse", inPlotAreaCommercialUse);
    data.append("doorToDoorGarbageCollection", doorToDoorGarbageCollection);
    data.append("garbageCollectionBy", garbageCollectionBy);
    data.append("agriculturalLand", agriculturalLand);
    data.append("courtCase", courtCase);
    data.append("familyMemberCriticalDisease", familyMemberCriticalDisease);
    data.append("nameOfDisease", nameOfDisease);
    data.append("nameOfMember", nameOfMember);
    data.append("domesticAnimal", domesticAnimal);
    data.append("rentedPerson", rentedPerson);
    // data.append("numberOfRentedFamilyMember", numberOfRentedFamilyMember);
    data.append("building-image", buildingImage);

    try {
      const response = await fetch("https://www.rcinfovision.in/api/submit-form", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error(
          `Error: ${response.status} - ${response.statusText}`,
          errorData
        );
        setSubmissionStatus("error");
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("Form submitted successfully", responseData);
      setSubmissionStatus("success");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <Container>
      <div className="my-5">
        <h2 className="text-center my-4 rajinfovision">
          <span style={{ color: "#02AFFE" }}>Raj Care Infovision </span>
          <br />
          <span style={{ color: "#7B91A2" }}>Digital Door Plate Form</span>
        </h2>
        {submissionStatus === "success" && (
          <Alert variant="success">Form submitted successfully!</Alert>
        )}
        {submissionStatus === "error" && (
          <Alert variant="danger">
            There was an error submitting the form. Please try again.
          </Alert>
        )}
        <Row>
          <Col md={4}>
            <Form.Group controlId="qrId">
              <Form.Label>QR ID</Form.Label>
              <Form.Control
                type="text"
                value={qrId}
                onChange={(e) => setQrId(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="state">
              <Form.Label>STATE</Form.Label>
              <Form.Control
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="district">
              <Form.Label>DISTRICT</Form.Label>
              <Form.Control
                type="text"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="municipality">
              <Form.Label>MUNICIPALITY</Form.Label>
              <Form.Control
                type="text"
                value={municipality}
                onChange={(e) => setMunicipality(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="policeStation">
              <Form.Label>POLICE STATION</Form.Label>
              <Form.Control
                type="text"
                value={policeStation}
                onChange={(e) => setPoliceStation(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="gpsLocation">
              <Form.Label>GPS LOCATION</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  value={gpsLocation}
                  onChange={(e) => setGpsLocation(e.target.value)}
                />
                <Button
                  variant="primary"
                  onClick={handleGetLocation}
                  className="ml-2 gpsbutton"
                >
                  Gps
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="nameOfHouseOwner">
              <Form.Label>NAME OF HOUSE OWNER</Form.Label>
              <Form.Control
                type="text"
                value={nameOfHouseOwner}
                onChange={(e) => setNameOfHouseOwner(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="houseUidNo">
              <Form.Label>HOUSE NO</Form.Label>
              <Form.Control
                type="text"
                value={houseNo}
                onChange={(e) => setHouseNo(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="municipalWardSectorNo">
              <Form.Label>MUNICIPAL WARD SECTOR NO</Form.Label>
              <Form.Control
                type="text"
                value={municipalWardSectorNo}
                onChange={(e) => setMunicipalWardSectorNo(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="residentSince">
              <Form.Label>RESIDENT SINCE</Form.Label>
              <Form.Control
                type="text"
                value={residentSince}
                onChange={(e) => setResidentSince(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="mobileNo">
              <Form.Label>MOBILE NO</Form.Label>
              <Form.Control
                type="text"
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="pincode">
              <Form.Label>PINCODE</Form.Label>
              <Form.Control
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="totalFamilyMembers">
              <Form.Label>TOTAL FAMILY MEMBERS</Form.Label>
              <Form.Control
                type="text"
                value={totalFamilyMembers}
                onChange={(e) => setTotalFamilyMembers(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="nonAadharFamilyMembers">
              <Form.Label>NON AADHAR FAMILY MEMBERS</Form.Label>
              <Form.Control
                type="text"
                value={nonAadharFamilyMembers}
                onChange={(e) => setNonAadharFamilyMembers(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="nonVoterFamilyMembers">
              <Form.Label>NON VOTER FAMILY MEMBERS</Form.Label>
              <Form.Control
                type="text"
                value={nonVoterFamilyMembers}
                onChange={(e) => setNonVoterFamilyMembers(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="passport">
              <Form.Label>PASSPORT</Form.Label>
              <Form.Control
                as="select"
                value={passport}
                onChange={(e) => setPassport(e.target.value)}
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="typeOfOwnership">
              <Form.Label>TYPE OF OWNERSHIP</Form.Label>
              <Form.Control
                as="select"
                value={typeOfOwnership}
                onChange={(e) => setTypeOfOwnership(e.target.value)}
              >
                <option value="">Select</option>
                <option value="single">Single</option>
                <option value="partnership">Partnership</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="statusOfProperty">
              <Form.Label>STATUS OF PROPERTY</Form.Label>
              <Form.Control
                as="select"
                value={statusOfProperty}
                onChange={(e) => setStatusOfProperty(e.target.value)}
              >
                <option value="">Select</option>
                <option value="pakka">Pakka</option>
                <option value="kacha">Kacha</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="propertyTaxPay">
              <Form.Label>PROPERTY TAX PAY</Form.Label>
              <Form.Control
                type="text"
                value={propertyTaxPay}
                onChange={(e) => setPropertyTaxPay(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="lastPaymentYear">
              <Form.Label>LAST PAYMENT YEAR</Form.Label>
              <Form.Control
                type="text"
                value={lastPaymentYear}
                onChange={(e) => setLastPaymentYear(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="govtWaterFacility">
              <Form.Label>GOVT WATER FACILITY</Form.Label>
              <Form.Control
                type="text"
                value={govtWaterFacility}
                onChange={(e) => setGovtWaterFacility(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="boreWell">
              <Form.Label>BORE WELL</Form.Label>
              <Form.Control
                type="text"
                value={boreWell}
                onChange={(e) => setBoreWell(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="latrineToilet">
              <Form.Label>LATRINE TOILET</Form.Label>
              <Form.Control
                type="text"
                value={latrineToilet}
                onChange={(e) => setLatrineToilet(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="electricity">
              <Form.Label>ELECTRICITY</Form.Label>
              <Form.Control
                type="text"
                value={electricity}
                onChange={(e) => setElectricity(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="caste">
              <Form.Label>CASTE</Form.Label>
              <Form.Control
                as="select"
                value={caste}
                onChange={(e) => setCaste(e.target.value)}
              >
                <option value="">Select</option>
                <option value="gen">General</option>
                <option value="obc">OBC</option>
                <option value="sc">SC</option>
                <option value="st">ST</option>
                <option value="others">Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="totalPlotArea">
              <Form.Label>TOTAL PLOT AREA</Form.Label>
              <Form.Control
                type="text"
                value={totalPlotArea}
                onChange={(e) => setTotalPlotArea(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="totalBuildCoveredArea">
              <Form.Label>TOTAL BUILD COVERED AREA</Form.Label>
              <Form.Control
                type="text"
                value={totalBuildCoveredArea}
                onChange={(e) => setTotalBuildCoveredArea(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="buildAreaFloorDetails">
              <Form.Label>BUILD AREA FLOOR DETAILS</Form.Label>
              <Form.Control
                type="text"
                value={buildAreaFloorDetails}
                onChange={(e) => setBuildAreaFloorDetails(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="inPlotAreaCommercialUse">
              <Form.Label>IN PLOT AREA COMMERCIAL USE</Form.Label>
              <Form.Control
                type="text"
                value={inPlotAreaCommercialUse}
                onChange={(e) => setInPlotAreaCommercialUse(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="doorToDoorGarbageCollection">
              <Form.Label>DOOR TO DOOR GARBAGE COLLECTION</Form.Label>
              <Form.Control
                type="text"
                value={doorToDoorGarbageCollection}
                onChange={(e) => setDoorToDoorGarbageCollection(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="garbageCollectionBy">
              <Form.Label>GARBAGE COLLECTION BY</Form.Label>
              <Form.Control
                type="text"
                value={garbageCollectionBy}
                onChange={(e) => setGarbageCollectionBy(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="agriculturalLand">
              <Form.Label>AGRICULTURAL LAND</Form.Label>
              <Form.Control
                type="text"
                value={agriculturalLand}
                onChange={(e) => setAgriculturalLand(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="courtCase">
              <Form.Label>COURT CASE</Form.Label>
              <Form.Control
                type="text"
                value={courtCase}
                onChange={(e) => setCourtCase(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="familyMemberCriticalDisease">
              <Form.Label>FAMILY MEMBER CRITICAL DISEASE</Form.Label>
              <Form.Control
                type="text"
                value={familyMemberCriticalDisease}
                onChange={(e) => setFamilyMemberCriticalDisease(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="nameOfDisease">
              <Form.Label>NAME OF DISEASE</Form.Label>
              <Form.Control
                type="text"
                value={nameOfDisease}
                onChange={(e) => setNameOfDisease(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="nameOfMember">
              <Form.Label>NAME OF MEMBER</Form.Label>
              <Form.Control
                type="text"
                value={nameOfMember}
                onChange={(e) => setNameOfMember(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="domesticAnimal">
              <Form.Label>DOMESTIC ANIMAL</Form.Label>
              <Form.Control
                type="text"
                value={domesticAnimal}
                onChange={(e) => setDomesticAnimal(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="rentedPerson">
              <Form.Label>RENTED PERSON</Form.Label>
              <Form.Control
                type="text"
                value={rentedPerson}
                onChange={(e) => setRentedPerson(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="building-image">
              <Form.Label>Building Image</Form.Label>
              <Form.Control
                type="file"
                name="building-image" // Update field name to building-image
                onChange={handleImageChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          className="my-3"
        >
          Submit
        </Button>
      </div>
    </Container>
  );
};

export default PropertyForm;

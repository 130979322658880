import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-copy">
              <span>
                All Right Reserved
                <NavLink
                  to="https://rcinfovision.com/"
                  className="copyright-link ps-2"
                >
                  rcinfovision.com .
                </NavLink>
                <NavLink to="tel:+919319295289" className="copyright-link ps-2">
                  For enquiry +919319295289.
                </NavLink>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
